import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/services.scss"

const ServicesPage = () => (
  <Layout>
    <SEO title="Services" />
    <div className="subheader-img-services" />
    <h1 className="services-page-title">Services</h1>
    <div className="services-page-wrapper">
      <h2 className="service-title">Government</h2>
      <div className="service-card">
        <h3>Lobbying/Advocacy</h3>
        <p>
          Premier Legislative Consulting (PLC Texas) can effectively communicate
          to legislators and agency staff the reasoning and justification for
          any changes to state laws or the state budget. We make use of our
          comprehensive knowledge of the legislative process, public policy
          development, and our extensive network of contacts inside and outside
          of Texas state government to help ensure that those policies are
          implemented. Additionally, we can defend our client’s interests when
          new policies are being researched and developed.
        </p>
      </div>
      <div className="service-card">
        <h3>Sunset Review Navigation</h3>
        <p>
          PLC Texas can help navigate the state’s Sunset Review Process. The
          Texas Sunset Commission (a standing joint committee of the
          legislature) makes recommendations on whether or not to continue the
          functions of state agencies, and if so, how to improve their
          operations. These reviews, performed by a cadre of state-employed
          public policy professionals, can have major long-lasting effects on
          regulated professions and activities. We can draw on our experience of
          working both inside and outside the Sunset Review Process to help
          ensure positive impacts for our clients.
        </p>
      </div>
      <div className="service-card">
        <h3>Strategic Policy Development</h3>
        <p>
          PLC Texas can help identify and structure the goals, guiding
          principles, laws, and procedures needed to overcome the public policy
          challenges faced by your organization.
        </p>
      </div>
      <div className="service-card">
        <h3>Drafting Legislation and Rules</h3>
        <p>
          PLC Texas can draft the legislation, amendments, and agency rules your
          organization needs to achieve your policy objectives given existing
          laws and regulations. We can also prepare bill books for legislative
          staff to best reflect your issues and to get your bill carried and
          passed.
        </p>
      </div>
      <div className="service-card">
        <h3>Parliamentary Services</h3>
        <p>
          PLC Texas can develop parliamentary strategies, research potential
          points of order, and analyze committee and floor rules to help ensure
          legislative success for your legislative initiatives.
        </p>
      </div>
      <div className="service-card">
        <h3>Government Business Development</h3>
        <p>
          PLC Texas can also custom design effective strategies to help
          successfully navigate state agency bureaucracies/applications to help
          increase the possibilities of our clients winning B2G contracts,
          grants, seed funding, and P3 partnerships.
        </p>
      </div>
      <div className="service-card">
        <h3>Regulatory Services</h3>
        <p>
          PLC Texas can effectively assist in state agency relations. We can
          help in positively affecting state agency implementation of
          legislation and development of agency rules that affect our clients.
          We can also help our clients to successfully interact with state
          agency personnel implementing existing state programs.
        </p>
      </div>

      <h2 className="service-title">Communications</h2>
      <div className="service-card">
        <h3>Editorial Services</h3>
        <p>
          PLC Texas can draft compelling white papers, press releases,
          editorials, and other documents to help convey our clients’ positions
          concerning issues that affect them.
        </p>
      </div>
      <div className="service-card">
        <h3>Grassroots/Grasstops Campaigns</h3>
        <p>
          PLC Texas can design effective custom grassroots/grasstops campaigns
          to spread understanding of our clients’ goals to their membership,
          employees, the general public, or possible allies and help demonstrate
          broad-based support of those goals to legislators and other key
          officials.
        </p>
      </div>
      <div className="service-card">
        <p>
          PLC Texas is also happy to participate in c-level meetings, seminars,
          and conferences to enhance legislative, public policy, and political
          understanding.
        </p>
      </div>

      <h2 className="service-title">Political Action</h2>
      <div className="service-card">
        <h3>Political Action Plan</h3>
        <p>
          PLC Texas can custom design an effective political action plan for our
          clients to help achieve their public policy goals.
        </p>
      </div>
      <div className="service-card">
        <h3>PAC Registration</h3>
        <p>
          PLC Texas can complete the necessary registration forms to help ensure
          your political action committee (PAC) meets the requirements of the
          Texas Ethics Commission.
        </p>
      </div>
      <div className="service-card">
        <h3>PAC Management</h3>
        <p>
          If appropriate, PLC Texas can effectively help to manage your PAC to
          help ensure proper record keeping and reporting to the Texas Ethics
          Commission.
        </p>
      </div>
      <h2 className="service-title">International Relations</h2>
      <div className="service-card">
        <h3>Consular Services</h3>
        <p>
          PLC Texas can help your company or organization do business abroad and
          establish international ties. Over 100 nations have consulates in
          Texas making it a major hub for international business. We can use our
          international experience to help expand your reach globally.
        </p>
      </div>
    </div>
  </Layout>
)

export default ServicesPage
